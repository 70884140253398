/* eslint-disable camelcase */
/* eslint-disable max-len */
import {
  getAuth
} from './identity.service';
import {
  deleteWithAuth, getWithOutAuth, getWithAuth, patchWithAuth, patchWithOutAuth,
  postWithAuth, getWithAuthServerSide, postWithOutAuth, putWithAuth
} from './http.service';
import {
  GET_USER_TEAMS_URL, GET_USER_DETAILS_URL,
  CONNECT_TO_YOUTUBE_STREAM_URL, CREATE_ENGAGEMENT_URL, GET_ENGAGEMENT_PROCESSED_COMMENTS,
  DISCONNECT_YOUTUBE_STREAM_URL, GET_YOUTUBE_STREAMS_URL, STOP_ENGAGEMENT_URL,
  UPDATE_ENGAGEMENT_TYPE_URL, GET_DEFAULT_COLORS, GET_CUSTOM_COLORS, UPDATE_COLORS,
  DEAUHTORIZE_YOUTUBE_URL, GET_LIVESTREAM_URL, GET_TEAMS_MEETINGS_URL,
  GET_PLATFORM_BY_USER_ID, CREATE_LIVE_STREAM_URL, GET_USER_ACCOUNT_URL, UPDATE_STREAM_COLORS,
  CREATE_QUESTION_URL, GET_QUESTIONS_BY_LIVESTREAM_URL,
  CREATE_OPTION_URL, UPDATE_OPTION_URL, DELETE_OPTION_URL, DELETE_QUESTION_URL, UPDATE_QUESTION_URL,
  UPDATE_ENGAGEMENT_URL, GET_QUESTION_URL, GET_QUESTIONS_URL, GET_COMMENT_URL, GET_ZOOM_MEETINGS_URL, CREATE_ZOOM_PLATFORM_URL,
  GET_LIVESTREAM_BY_USER_URL, POST_ZOOM_COMMENT_URL, POST_ZOOM_USER_MEETINGS_URL,
  GET_DEFAULT_SETTINGS, CREATE_ROS_QUESTION_URL, CREATE_ROS_TEMPLATE_URL,
  GET_TEMPLATES_URL, CREATE_ROS_OPTION_URL, DELETE_ROS_TEMPLATE_URL, UPDATE_ROS_QUESTIONS_URL, GET_ALL_TEMPLATE_URL,
  UPDATE_ROS_QUESTION_URL, GET_LINK_QUESTIONS_URL, GET_LINK_ROS_QUESTIONS_STATUS_URL, DELETE_OPTIONS_URL,
  GET_CHAT_ID_FOR_TEAM_URL, DELETE_POLLS_URL, UPDATE_COMMENTS_URL, DELETE_WORDCLOUDS_URL, DELETE_MAPS_URL,
  UPDATE_LIVE_STREAM_URL, GET_ADMIN_SETTINGS, UPDATE_ADMIN_SETTINGS, UPDATE_FREEFROM_POLL_URL,
  GET_FREEFORM_POLL_COMMENT_URL, GET_ENGAGEMENT_FEATURE_URL, GET_TEMPLATE_TITLE_URL, USER_LOGIN_URL, USER_VERIFY_URL,
  UPDATE_WORD_CLOUD_URL, getStreamsForPage, GET_MAP_PROCESSED_COMMENTS_COUNT, SEND_ANALYTICS_REPORTS_TO_USER,
  GET_FEATURE_BY_QID, GET_STREAM_RESULT, UPDATE_LIVE_STREAM,
  TEAM_URL, TEAM_MEMBERS_URL, TEAM_MEMBERS_DETAIL_URL, TEAM_GENERATE_TOKEN_URL, TEAM_VERIFY_TOKEN_URL,
  CREATE_IPA_PLATFORM_URL, POST_IPA_USER_EVENT_URL, GET_IPA_EVENTS_URL, DELETE_IPA_EVENT_URL,
  UPDATE_IPA_EVENT_URL, POST_IPA_COPILOT_URL, POST_IPA_GENERIC_COPILOT_URL, SHARE_IPA_USER_EVENT_DETAILS_URL,
  GET_IPA_EVENT_BY_SLUG_URL, WAKE_UP_LAMBDA_URL,
  CREATE_IPA_AUTH_URL, GET_IPA_EVENT_BY_ID_URL,
  DELETE_ZOOM_STREAMS, UPDATE_ZOOM_MEETING, GET_QUICK_QUESTIONS,
  CREATE_MEET_PLATFORM_URL, CREATE_MEET_USER_MEETING, DELETE_MEET_MEETING, UPDATE_MEET_MEETING,
  GET_MEET_MEETINGS_URL, UPDATED_INTERESTED_PLATFORMS, GET_ANALYTICS_URL, GET_COMMENTS_DETAILS,
  GENERATE_ZOOM_SIGNATURE_URL, GET_FEATURE_COMMENTS_DETAILS, UPDATE_LIVESTREAM_SESSION_URL,
  GET_LINK_LIBRARY_URL, UPDATE_EMOJI_EVERYWHERE, GET_TWITCH_STREAMS_URL, GET_TEXT_TRACK_COMMENTS, GET_PLATFORM_ID_BY_USER_ID_URL, CREATE_VIMEO_PLATFORM_URL,
  GET_VIMEO_MEETINGS_URL, CREATE_VIMEO_USER_MEETING, DELETE_VIMEO_MEETING, UPDATE_VIMEO_MEETING, COPILOT_RESTRICT_COPILOT, GUEST_LOGIN_URL, GET_LINK_QUESTIONS_URL_PAGINATED, GET_QUICK_QUESTIONS_PAGINATED, UPDATE_USER_DETAILS, UPDATE_LIVE_STREAM_SETTINGS,
  CREATE_WEBEX_PLATFORM_URL, GET_WEBEX_MEETINGS_URL, CREATE_WEBEX_USER_MEETING, DELETE_WEBEX_MEETING, UPDATE_WEBEX_MEETING,
  SEND_MAX_PARTICIPANTS_ALERT,
  SANDBOX_GET_ROS_TEMPLATE_URL,
  SEND_MAX_TIME_ALERT,
  CREATE_LINKEDIN_PLATFORM_URL, GET_LINKEDIN_MEETINGS_URL, CREATE_LINKEDIN_USER_MEETING, DELETE_LINKEDIN_MEETING, UPDATE_LINKEDIN_MEETING,
  GET_ZOOM_MEETING_URL,
  UPDATE_LIVE_STREAM_DATA,
  GET_SCREENSHOTS,
  GET_MEET_MEETING_URL,
  GET_SCREENSHOTS_PDF,
  GET_FANTASTIC_FANS_PAGINATED,
  GET_ME_URL,
  GET_CELLO_JWT_TOKEN,
  GET_ACTIVE_LINK_LIVESTREAM_URL,
  GET_QUESTION_BY_LINK_ID,
  GET_SCREENSHOT_DETAILS,
  CONVERT_FILE_TO_IMAGES,
  CREATE_RESTREAM_PLATFORM_URL, GET_RESTREAM_MEETINGS_URL, CREATE_RESTREAM_USER_MEETING, DELETE_RESTREAM_MEETING, UPDATE_RESTREAM_MEETING,
  CREATE_STREAMYARD_PLATFORM_URL, GET_STREAMYARD_MEETINGS_URL, CREATE_STREAMYARD_USER_MEETING, DELETE_STREAMYARD_MEETING, UPDATE_STREAMYARD_MEETING,
  CREATE_AIRMEET_PLATFORM_URL, GET_AIRMEET_MEETINGS_URL, CREATE_AIRMEET_USER_MEETING, DELETE_AIRMEET_MEETING, UPDATE_AIRMEET_MEETING,
  GET_LIVESTREAM_URL_NO_AUTH,
  GET_CUMULATIVE_HEATMAP_ANALYTICS_URL
} from './url.service';

export const getUserAccountById = () => getWithAuth(GET_USER_ACCOUNT_URL());

export const updateUserAccountById = (id, data) => patchWithAuth(UPDATE_USER_DETAILS(id), data);

export const getUserTeams = () => getWithAuth(GET_USER_TEAMS_URL());

export const getUserDetails = (id) => getWithAuth(
  GET_USER_DETAILS_URL(id)
);

export const getUserAccountByIdServerSide = (id, auth) => getWithAuthServerSide(GET_USER_ACCOUNT_URL(id), auth);

export const getMeServerSide = (auth) => getWithAuthServerSide(GET_ME_URL(), auth);

export const getTeamById = (id) => getWithAuth(TEAM_URL(id));

export const updateTeamApi = (id, data) => patchWithAuth(TEAM_URL(id), data);

export const getTeamByIdServiceSide = (id, auth) => getWithAuthServerSide(TEAM_URL(id), auth);

export const generateTeamToken = (teamId) => postWithAuth(TEAM_GENERATE_TOKEN_URL(), { teamId });

export const verifyTeamToken = (token) => postWithAuth(TEAM_VERIFY_TOKEN_URL(), { token });

export const restrictCopilot = (pathname) => getWithAuth(COPILOT_RESTRICT_COPILOT(pathname), {});

export const inviteTeamMember = (id, data) => postWithAuth(TEAM_MEMBERS_URL(id), data);

export const getTeamMember = (id, eventId) => patchWithAuth(
  TEAM_MEMBERS_DETAIL_URL(id, eventId)
);

export const getTeamMemberServiceSide = (id, eventId) => getWithOutAuth(
  TEAM_MEMBERS_DETAIL_URL(id, eventId)
);

export const removeTeamMember = (id, eventId) => deleteWithAuth(
  TEAM_MEMBERS_DETAIL_URL(id, eventId)
);

export const updateTeamMemberPublic = (id, eventId, data) => patchWithOutAuth(TEAM_MEMBERS_DETAIL_URL(id, eventId), data);

export const updateTeamMember = (id, eventId, data) => patchWithAuth(TEAM_MEMBERS_DETAIL_URL(id, eventId), data);

export const getYoutubeStreams = (id, hybrid) => getWithAuth(GET_YOUTUBE_STREAMS_URL(id, hybrid));

export const createStream = (data) => postWithAuth(CREATE_LIVE_STREAM_URL(), data);

export const connectToYoutubeStream = (data) => postWithAuth(CONNECT_TO_YOUTUBE_STREAM_URL(), data);

export const disconnetFromYoutubeStream = (id, data) => postWithAuth(DISCONNECT_YOUTUBE_STREAM_URL(id), data);

export const updateEngageType = (id, engageType, data) => patchWithAuth(UPDATE_ENGAGEMENT_TYPE_URL(id, engageType), data);

export const createEngagement = (id, engageType, data) => {
  const auth = getAuth();
  const actor = auth?.id;
  return postWithAuth(CREATE_ENGAGEMENT_URL(id, engageType), { ...data, actor });
};

export const stopEngagement = (id, engageType, data) => deleteWithAuth(STOP_ENGAGEMENT_URL(id, engageType), data);

export const getCommentsOnRefresh = (id, engageType, auth) => getWithAuthServerSide(GET_ENGAGEMENT_PROCESSED_COMMENTS(id, engageType), auth);

export const getDefaultSettings = (auth) => getWithAuthServerSide(GET_DEFAULT_SETTINGS(), auth);

export const getDefaultColours = () => getWithAuth(
  GET_DEFAULT_COLORS()
);

export const getCustomColours = (id, auth) => getWithAuthServerSide(GET_CUSTOM_COLORS(id), auth);

export const updateColours = (userAccId, data) => patchWithAuth(UPDATE_COLORS(userAccId), data);

export const deauthorize = (id, userId) => postWithAuth(DEAUHTORIZE_YOUTUBE_URL(id), { userId });

export const getYoutubeStreamsServerSide = (id, auth) => getWithAuthServerSide(GET_YOUTUBE_STREAMS_URL(id), auth);

export const getLiveStreamById = (id, auth) => getWithAuthServerSide(GET_LIVESTREAM_URL(id), auth);

export const getLiveStreamByIdNoAuth = (id) => getWithOutAuth(GET_LIVESTREAM_URL_NO_AUTH(id));

export const getLiveStreamByIdClient = (id, auth) => getWithAuth(GET_LIVESTREAM_URL(id), auth);

export const getAnalyticsByStreamId = (id) => getWithOutAuth(
  GET_ANALYTICS_URL(id)
);

export const getCumulativeHeatmapAnalyticsByStreamId = (id) => getWithAuth(
  GET_CUMULATIVE_HEATMAP_ANALYTICS_URL(id)
);

export const getIpaEventById = (id, auth) => getWithAuthServerSide(GET_IPA_EVENT_BY_ID_URL(id), auth);

export const getPlatformsByUserId = (id) => {
  const auth = getAuth();
  const userId = id || auth.id;
  return getWithAuth(
    GET_PLATFORM_BY_USER_ID(userId)
  );
};

export const getTeamsMeetings = (id, email, startDate, endDate, hybrid) => getWithAuth(GET_TEAMS_MEETINGS_URL(id, email, startDate, endDate, hybrid));

export const updateStreamColors = (liveStreamId, data) => putWithAuth(UPDATE_STREAM_COLORS(liveStreamId), data);

export const createQuestion = (streamId, data) => postWithAuth(CREATE_QUESTION_URL(streamId), data);

export const createOption = (questionId, data) => postWithOutAuth(CREATE_OPTION_URL(questionId), data);

export const getQuestions = (userId, type) => getWithAuth(GET_QUESTIONS_URL(userId, type));

export const getQuestionsByLivestream = (liveStreamId, engagementType) => getWithAuth(
  GET_QUESTIONS_BY_LIVESTREAM_URL(liveStreamId, engagementType)
);

export const updateOption = (id, data) => putWithAuth(UPDATE_OPTION_URL(id), data);

export const deleteOptionById = (questionId, id) => deleteWithAuth(
  DELETE_OPTION_URL(questionId, id)
);

export const deleteQuestion = (id) => deleteWithAuth(
  DELETE_QUESTION_URL(id)
);
export const deleteIpaEvent = (id) => deleteWithAuth(
  DELETE_IPA_EVENT_URL(id)
);

export const getIpaEventBySlug = (slug) => getWithOutAuth(
  GET_IPA_EVENT_BY_SLUG_URL(slug)
);

export const createIpaAuth = (data) => postWithOutAuth(CREATE_IPA_AUTH_URL(), data);

export const updateQuestion = (id, data) => putWithAuth(UPDATE_QUESTION_URL(id), data);

export const getQuestionsByLiveStreamIdSSR = (liveStreamId, engagementType, auth) => getWithAuthServerSide(GET_QUESTIONS_BY_LIVESTREAM_URL(liveStreamId, engagementType), auth);

export const updateEngagement = (id, engagementType, data) => putWithAuth(UPDATE_ENGAGEMENT_URL(id, engagementType), data);

export const getQuestion = (id) => getWithAuth(
  GET_QUESTION_URL(id)
);

export const getQuestionServerSide = (id, auth) => getWithAuthServerSide(GET_QUESTION_URL(id), auth);

export const getComments = (streamId, pageNumber, auth) => getWithAuthServerSide(GET_COMMENT_URL(streamId, pageNumber), auth);

export const getZoomMeetingById = (id) => getWithAuth(
  GET_ZOOM_MEETING_URL(id)
);
export const getZoomMeetings = (id, type, startDate, endDate) => getWithAuth(
  GET_ZOOM_MEETINGS_URL(id, type, startDate, endDate)
);

export const getIpaEvents = ({ limit = 100, offset = 0, event_type = 'ipa' }) => getWithAuth(
  GET_IPA_EVENTS_URL({ limit, offset, event_type })
);

export const shareIpaEventDetails = (id, data) => postWithAuth(SHARE_IPA_USER_EVENT_DETAILS_URL(id), data);

export const createZoomPlatform = (data) => postWithAuth(CREATE_ZOOM_PLATFORM_URL(), data);

export const createIpaPlatform = (data) => postWithAuth(CREATE_IPA_PLATFORM_URL(), data);
export const GetLiveStreamByUserId = (userId, page, auth) => {
  getWithAuthServerSide(GET_LIVESTREAM_BY_USER_URL(userId, page), auth);
};

export const postZoomComment = (data) => postWithAuth(POST_ZOOM_COMMENT_URL(), data);

export const updateFreeFormPoll = (id, data) => putWithAuth(UPDATE_FREEFROM_POLL_URL(id), data);

export const updateWordCloud = ({ id, data }) => putWithAuth(UPDATE_WORD_CLOUD_URL(id), data);

export const updateEmojiEveryWhereFeature = ({ id, data }) => putWithAuth(UPDATE_EMOJI_EVERYWHERE(id), data);

export const getFreeFormPollComments = (id) => getWithAuth(
  GET_FREEFORM_POLL_COMMENT_URL(id)
);

export const postZoomUserMeeting = (id, data) => postWithAuth(POST_ZOOM_USER_MEETINGS_URL(id), data);

export const postIpaUserEvent = (data) => postWithAuth(POST_IPA_USER_EVENT_URL(), data);
export const updateIpaEvent = (id, data) => putWithAuth(UPDATE_IPA_EVENT_URL(id), data);
export const addIpaCopilot = (eventId, data) => postWithAuth(POST_IPA_COPILOT_URL(eventId), data);
export const addIpaGenericCopilot = (eventId, data) => postWithAuth(POST_IPA_GENERIC_COPILOT_URL(eventId), data);
export const getDefaultColorsInServerSide = (auth) => getWithAuthServerSide(GET_DEFAULT_COLORS(), auth);

export const createRosQuestions = (data) => postWithAuth(CREATE_ROS_QUESTION_URL(), data);

export const createRosTemplate = (data) => postWithAuth(CREATE_ROS_TEMPLATE_URL(), data);

export const getRosTemplates = (userId, queryParams, auth) => getWithAuthServerSide(GET_TEMPLATES_URL(userId, queryParams), auth);

export const createRosOptions = (data) => postWithAuth(CREATE_ROS_OPTION_URL(), data);

export const deleteRosTemplate = (id) => deleteWithAuth(
  DELETE_ROS_TEMPLATE_URL(id)
);

export const getAllRosTemplate = (userId, auth, teamToken) => getWithAuthServerSide(GET_ALL_TEMPLATE_URL(userId), auth, teamToken);

export const getAllRosTemplateClient = (userId) => getWithAuth(GET_ALL_TEMPLATE_URL(userId));

export const updateRosQuestion = (id, data) => putWithAuth(UPDATE_ROS_QUESTION_URL(id), data);

export const updateRosQuestions = (id, data) => putWithAuth(UPDATE_ROS_QUESTIONS_URL(id), data);

export const getLinkQuestions = (id) => getWithOutAuth(
  GET_LINK_QUESTIONS_URL(id)
);

export const getLinkQuestionStatus = (streamId) => getWithAuth(
  GET_LINK_ROS_QUESTIONS_STATUS_URL(streamId)
);

export const deleteRosOptions = (id) => deleteWithAuth(
  DELETE_OPTIONS_URL(id)
);

export const getTeamsIds = (platformId, entityId) => getWithAuth(
  GET_CHAT_ID_FOR_TEAM_URL(platformId, entityId)
);

export const deletePolls = (pollId) => deleteWithAuth(
  DELETE_POLLS_URL(pollId)
);

export const updateComments = (streamId) => putWithAuth(
  UPDATE_COMMENTS_URL(streamId)
);

export const deleteWordClouds = (wordcloudId) => deleteWithAuth(
  DELETE_WORDCLOUDS_URL(wordcloudId)
);

export const deleteMaps = (mapId) => deleteWithAuth(
  DELETE_MAPS_URL(mapId)
);

export const updateLiveStreams = (id, data) => putWithAuth(UPDATE_LIVE_STREAM_URL(id), data);

export const getAdminSettings = () => getWithAuth(GET_ADMIN_SETTINGS());

export const updateAdminSettings = (data) => patchWithAuth(UPDATE_ADMIN_SETTINGS(), data);

export const getFeature = (id, engageType) => getWithAuth(
  GET_ENGAGEMENT_FEATURE_URL(id, engageType)
);

export const getTemplateTitle = (tempId, auth, userId) => getWithAuthServerSide(GET_TEMPLATE_TITLE_URL(tempId, userId), auth);

export const getLinkedRosTemplate = (tempId) => getWithAuth(
  GET_TEMPLATE_TITLE_URL(tempId)
);

export const userLogin = (data) => postWithOutAuth(USER_LOGIN_URL(), data, data?.email);

export const guestLogin = (data) => postWithOutAuth(GUEST_LOGIN_URL(), data, data?.email);

export const verifyLogin = (data) => postWithOutAuth(USER_VERIFY_URL(), data, data?.email);

export const userLoginFromSandbox = (data) => postWithOutAuth(`${process.env.NEXT_PUBLIC_MAIN_API_URL}login`, data, data?.email);

export const verifyLoginFromSandbox = (data) => postWithOutAuth(`${process.env.NEXT_PUBLIC_MAIN_API_URL}login/verify`, data, data?.email);

export const getStreamsForPages = (userId, page) => getWithAuth(
  getStreamsForPage(userId, page)
);

export const getMapProcessedCommentsCount = (mapId, auth) => getWithAuth(GET_MAP_PROCESSED_COMMENTS_COUNT(mapId), auth);

export const sendReportsToTheUser = (streamId) => getWithAuth(
  SEND_ANALYTICS_REPORTS_TO_USER(streamId)
);

export const getFeatureByQId = (questionId, streamId, type) => getWithAuth(
  GET_FEATURE_BY_QID(questionId, streamId, type)
);

export const getStreamResult = (streamId, auth) => getWithAuthServerSide(GET_STREAM_RESULT(streamId), auth);

export const getWheelResults = (streamId) => getWithAuth(
  GET_STREAM_RESULT(streamId)
);

export const updateLiveStreamSettings = (id, data) => putWithAuth(UPDATE_LIVE_STREAM_SETTINGS(id), data);
export const updateLiveStream = (id, data) => patchWithAuth(UPDATE_LIVE_STREAM(id), data);

export const updateRosTemplate = (id, data) => putWithAuth(DELETE_ROS_TEMPLATE_URL(id), data);

export const getRosTemplateByTitle = ({ title, userId }) => getWithAuth(
  CREATE_ROS_TEMPLATE_URL(title, userId)
);

export const deleteZoomStreams = (id) => deleteWithAuth(
  DELETE_ZOOM_STREAMS(id)
);

export const updateZoomMeeting = (id, data) => putWithAuth(UPDATE_ZOOM_MEETING(id), data);

export const updateLiveStreamData = (id, data) => putWithAuth(UPDATE_LIVE_STREAM_DATA(id), data);

export const wakeUpLambdaFunctions = () => postWithAuth(
  WAKE_UP_LAMBDA_URL()
);

export const getQuickQuestions = (streamId, isReport, auth) => getWithAuthServerSide(GET_QUICK_QUESTIONS(streamId, isReport), auth);

export const updatedInterestedPlatforms = (platform) => postWithAuth(UPDATED_INTERESTED_PLATFORMS(), { platform });

export const getQuickQuestionsPaginated = (streamId, queryParams, auth) => getWithAuth(GET_QUICK_QUESTIONS_PAGINATED(streamId, queryParams), auth);

export const createMeetPlatform = (data) => postWithAuth(CREATE_MEET_PLATFORM_URL(), data);

export const createMeetUserMeeting = (id, data) => postWithAuth(CREATE_MEET_USER_MEETING(id), data);

export const deleteMeetMeeting = (id) => deleteWithAuth(
  DELETE_MEET_MEETING(id)
);

export const updateMeetMeeting = (id, data) => putWithAuth(UPDATE_MEET_MEETING(id), data);

export const getMeetMeetingById = (id) => getWithAuth(
  GET_MEET_MEETING_URL(id)
);
export const getMeetMeetings = (id, startDate, endDate) => getWithAuth(
  GET_MEET_MEETINGS_URL(id, startDate, endDate)
);

export const getCommentsDetails = (streamId, wheelUser) => getWithAuth(
  GET_COMMENTS_DETAILS(streamId, wheelUser)
);
export const generateZoomSignature = (meetingId) => getWithOutAuth(
  GENERATE_ZOOM_SIGNATURE_URL(meetingId)
);

export const updateLivestreamSession = (sessionId, data) => putWithAuth(UPDATE_LIVESTREAM_SESSION_URL(sessionId), data);

export const getFeaturesCommentDetails = (streamId, featureId, wheelShowUser) => getWithAuth(
  GET_FEATURE_COMMENTS_DETAILS(streamId, featureId, wheelShowUser)
);

export const getLinkLibrary = (streamId, skip, filter) => getWithOutAuth(
  GET_LINK_LIBRARY_URL(streamId, skip, filter)
);

export const getTwitchStreams = (id) => getWithAuth(GET_TWITCH_STREAMS_URL(id));

export const getTextTrackComments = (streamId, profanityFilter) => getWithAuth(
  GET_TEXT_TRACK_COMMENTS(streamId, profanityFilter)
);

export const getLinkLibraryPaginated = (streamId, queryParams, auth) => getWithAuth(GET_LINK_QUESTIONS_URL_PAGINATED(streamId, queryParams), auth);

export const getStream = (streamId) => getWithAuth(
  GET_LIVESTREAM_URL(streamId)
);

export const getPlatformIdByUserId = (id, platformType) => {
  const auth = getAuth();
  const userId = id || auth.id;
  return getWithAuth(
    GET_PLATFORM_ID_BY_USER_ID_URL(userId, platformType)
  );
};

export const createSandboxRosTemplate = (hash) => getWithOutAuth(
  SANDBOX_GET_ROS_TEMPLATE_URL(hash)
);

export const createVimeoPlatform = (data) => postWithAuth(CREATE_VIMEO_PLATFORM_URL(), data);

export const getVimeoMeetings = (id, startDate, endDate) => getWithAuth(
  GET_VIMEO_MEETINGS_URL(id, startDate, endDate)
);

export const createVimeoUserMeeting = (id, data) => postWithAuth(CREATE_VIMEO_USER_MEETING(id), data);

export const deleteVimeoMeeting = (id) => deleteWithAuth(
  DELETE_VIMEO_MEETING(id)
);

export const updateVimeoMeeting = (id, data) => putWithAuth(UPDATE_VIMEO_MEETING(id), data);

export const createWebexPlatform = (data) => postWithAuth(CREATE_WEBEX_PLATFORM_URL(), data);

export const getWebexMeetings = (id, startDate, endDate) => getWithAuth(
  GET_WEBEX_MEETINGS_URL(id, startDate, endDate)
);

export const createWebexUserMeeting = (id, data) => postWithAuth(CREATE_WEBEX_USER_MEETING(id), data);

export const deleteWebexMeeting = (id) => deleteWithAuth(
  DELETE_WEBEX_MEETING(id)
);

export const updateWebexMeeting = (id, data) => putWithAuth(UPDATE_WEBEX_MEETING(id), data);

export const sendMaxParticipantsAlert = (streamId) => postWithAuth(
  SEND_MAX_PARTICIPANTS_ALERT(streamId)
);

export const sendMaxTimeAlert = (streamId) => postWithAuth(
  SEND_MAX_TIME_ALERT(streamId)
);

export const createLinkedInPlatform = (data) => postWithAuth(CREATE_LINKEDIN_PLATFORM_URL(), data);

export const getLinkedInMeetings = (id, startDate, endDate) => getWithAuth(
  GET_LINKEDIN_MEETINGS_URL(id, startDate, endDate)
);

export const createLinkedInUserMeeting = (id, data) => postWithAuth(CREATE_LINKEDIN_USER_MEETING(id), data);

export const deleteLinkedInMeeting = (id) => deleteWithAuth(
  DELETE_LINKEDIN_MEETING(id)
);

export const updateLinkedInMeeting = (id, data) => putWithAuth(UPDATE_LINKEDIN_MEETING(id), data);
export const getScreenshots = (streamId, data) => postWithOutAuth(GET_SCREENSHOTS(streamId), data);
export const getScreenshotsPdf = (data) => postWithOutAuth(GET_SCREENSHOTS_PDF(), data);

export const getFantasticFansPaginated = (streamId) => getWithAuth(GET_FANTASTIC_FANS_PAGINATED(streamId));

export const getCelloJwtToken = (userId) => getWithAuth(GET_CELLO_JWT_TOKEN(userId));

export const getActiveLinkLivestream = (userId) => getWithAuth(GET_ACTIVE_LINK_LIVESTREAM_URL(userId));

export const getQuestionByLinkId = (link_id) => getWithOutAuth(GET_QUESTION_BY_LINK_ID(link_id));
export const getScreenshotDetails = (stream_id, screenshot_id) => getWithOutAuth(GET_SCREENSHOT_DETAILS(stream_id, screenshot_id));

export const convertFileToImages = (data) => postWithAuth(CONVERT_FILE_TO_IMAGES(), data);

export const createRestreamPlatform = (data) => postWithAuth(CREATE_RESTREAM_PLATFORM_URL(), data);
export const getRestreamMeetings = (id, startDate, endDate) => getWithAuth(GET_RESTREAM_MEETINGS_URL(id, startDate, endDate));
export const createRestreamUserMeeting = (id, data) => postWithAuth(CREATE_RESTREAM_USER_MEETING(id), data);
export const deleteRestreamMeeting = (id) => deleteWithAuth(DELETE_RESTREAM_MEETING(id));
export const updateLRestreamMeeting = (id, data) => putWithAuth(UPDATE_RESTREAM_MEETING(id), data);

export const createStreamyardPlatform = (data) => postWithAuth(CREATE_STREAMYARD_PLATFORM_URL(), data);
export const getStreamyardMeetings = (id, startDate, endDate) => getWithAuth(GET_STREAMYARD_MEETINGS_URL(id, startDate, endDate));
export const createStreamyardUserMeeting = (id, data) => postWithAuth(CREATE_STREAMYARD_USER_MEETING(id), data);
export const deleteStreamyardMeeting = (id) => deleteWithAuth(DELETE_STREAMYARD_MEETING(id));
export const updateStreamyardMeeting = (id, data) => putWithAuth(UPDATE_STREAMYARD_MEETING(id), data);

export const createAirmeetPlatform = (data) => postWithAuth(CREATE_AIRMEET_PLATFORM_URL(), data);
export const getAirmeetMeetings = (id, startDate, endDate) => getWithAuth(GET_AIRMEET_MEETINGS_URL(id, startDate, endDate));
export const createAirmeetUserMeeting = (id, data) => postWithAuth(CREATE_AIRMEET_USER_MEETING(id), data);
export const deleteAirmeetMeeting = (id) => deleteWithAuth(DELETE_AIRMEET_MEETING(id));
export const updateAirmeetMeeting = (id, data) => putWithAuth(UPDATE_AIRMEET_MEETING(id), data);
